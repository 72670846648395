import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://127.0.0.1:5000/api/v1/",
// });
const axiosInstance = axios.create({
  baseURL: "https://api.orohandel.com/api/v1/",
});

export const signIn = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/loginAdmin",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const signOut = async () => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "users/logout",
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getOneUser = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `users/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUsers = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: "users",
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const creditReferer = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `users/creditReferer/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteUser = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "DELETE",
      url: `users/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getInvestments = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: "subscriptions",
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const approveInvestment = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `subscriptions/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getInvestment = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `subscriptions/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getWithdrawReqs = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `withdrawals`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const approveWithdrawReq = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `withdrawals/approveWithdraw`,
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getOneWithdrawReq = async (id, jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `withdrawals/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getDepositReqs = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `deposits`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const approveDepositReq = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `deposits/approveDeposit`,
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
