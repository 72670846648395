import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import Investment from "./Investment";
import classes from "./Investments.module.css";
import { getInvestments } from "../../api/api";

const Investments = () => {
  const res = useLoaderData();
  const investments = res.data.subscriptions;

  return (
    <section className={classes.investments}>
      {investments.map((data) => (
        <Investment
          key={data._id}
          id={data._id}
          planName={data.plan.name}
          planPercentage={data.plan.percentage}
          name={data.user.name}
          amount={data.amount}
          createdAt={data.createdAt}
          updatedAmount={data.updatedAmount}
          updatedAt={data.updatedAt}
          status={data.active}
        />
      ))}
    </section>
  );
};

export default Investments;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getInvestments(jwt);
};
