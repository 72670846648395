import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import Withdraw from "./Withdraw";
import classes from "./Withdraws.module.css";
import { getWithdrawReqs } from "../../api/api";

const Withdraws = () => {
  const res = useLoaderData();
  const withdraws = res.data.withdrawals;

  return (
    <section className={classes.withdraws}>
      {withdraws.map((data) => (
        <Withdraw
          key={data._id}
          id={data._id}
          wallet={data.wallet}
          name={data.user.name}
          amount={data.amount.toFixed(2)}
          status={data.active}
        />
      ))}
    </section>
  );
};

export default Withdraws;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getWithdrawReqs(jwt);
};
