import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import Account from "./Account";
import classes from "./Accounts.module.css";
import { getUsers } from "../../api/api";

const Accounts = () => {
  const res = useLoaderData();
  const users = res.data.users;

  return (
    <section className={classes.accounts}>
      {users.map((user) => (
        <Account
          key={user._id}
          id={user._id}
          name={user.name}
          email={user.email}
          phone={user.phone}
        />
      ))}
    </section>
  );
};

export default Accounts;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getUsers(jwt);
};
