import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./Withdraw.module.css";
import { approveWithdrawReq } from "../../api/api";
import { spinnerActions } from "../../store/spinner-slice";
import { alertActions } from "../../store/alert-slice";

const Withdraw = (props) => {
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];

  const approveHandler = async () => {
    dispatch(spinnerActions.showSpinner());

    const res = await approveWithdrawReq({ id: props.id }, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      window.location.reload();
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    dispatch(spinnerActions.hideSpinner());
  };
  return (
    <div className={classes.withdraw}>
      <div className={classes.content}>
        <span>{props.wallet}</span>
      </div>
      <div className={classes.content}>
        <span>{props.name}</span>
      </div>
      <div className={classes.content}>
        <span>{props.amount}</span>
      </div>
      <div className={classes.content}>
        <Link to={`/withdrawals/${props.id}`}>Details</Link>
      </div>
      <div className={classes.content}>
        <button type="button" onClick={approveHandler}>
          Approve
        </button>
      </div>
    </div>
  );
};

export default Withdraw;
