import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import classes from "./Detail.module.css";
import { getInvestment } from "../../api/api";

const Detail = () => {
  const res = useLoaderData();
  const investment = res.data.investment;

  const dateCreated = new Date(investment.createdAt).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );

  const dateUpdated = new Date(investment.updatedAt).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );

  const status = investment.active ? "Approved" : "Unapproved";

  return (
    <section className={classes.detail}>
      <div className={classes.content}>
        <span>Plan name</span>
        <span>{investment.plan.name}</span>
      </div>
      <div className={classes.content}>
        <span>Amount</span>
        <span>{investment.amount.toFixed(2)}</span>
      </div>
      <div className={classes.content}>
        <span>Account Name</span>
        <span>{investment.user.name}</span>
      </div>
      <div className={classes.content}>
        <span>Weekly Returns</span>
        <span>{investment.plan.percentage}%</span>
      </div>
      <div className={classes.content}>
        <span>Balance</span>
        <span>{investment.updatedAmount.toFixed(2)}</span>
      </div>
      <div className={classes.content}>
        <span>Date created</span>
        <span>{dateCreated}</span>
      </div>
      <div className={classes.content}>
        <span>Last date updated</span>
        <span>{dateUpdated}</span>
      </div>
      <div className={classes.content}>
        <span>Status</span>
        <span>{status}</span>
      </div>
    </section>
  );
};

export default Detail;

export const loader = ({ params }) => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getInvestment(params.id, jwt);
};
