import { Fragment } from "react";
import ReactDOM from "react-dom";

import classes from "./LoadSpinner.module.css";
import spinner from "../../images/spinner.gif";
import Backdrop from "./Backdrop";

const SpinnerDiv = () => {
  return (
    <div className={classes.spinner}>
      <img src={spinner} alt="load-spinner" />
    </div>
  );
};

const LoadSpinner = () => {
  const backdropEl = document.getElementById("backdrop-root");
  const overlayEl = document.getElementById("overlay-root");
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, backdropEl)}
      {ReactDOM.createPortal(<SpinnerDiv />, overlayEl)}
    </Fragment>
  );
};

export default LoadSpinner;
