import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./Investment.module.css";
import { approveInvestment } from "../../api/api";
import { spinnerActions } from "../../store/spinner-slice";
import { alertActions } from "../../store/alert-slice";

const Investment = (props) => {
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];

  const approveHandler = async () => {
    dispatch(spinnerActions.showSpinner());

    const res = await approveInvestment(props.id, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      window.location.reload();
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }
    dispatch(spinnerActions.hideSpinner());
  };
  return (
    <div className={classes.investment}>
      <div className={classes.content}>
        <span>{props.planName}</span>
      </div>
      <div className={classes.content}>
        <span>{props.name}</span>
      </div>
      <div className={classes.content}>
        <span>{props.amount.toFixed(2)}</span>
      </div>
      <div className={classes.content}>
        <Link to={`/investments/${props.id}`}>Details</Link>
      </div>
      <div className={classes.content}>
        {!props.status && (
          <button type="button" onClick={approveHandler}>
            Approve
          </button>
        )}
        {props.status && <p>Approved</p>}
      </div>
    </div>
  );
};

export default Investment;
