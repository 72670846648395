import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import classes from "./WithdrawDetail.module.css";
import { getOneWithdrawReq } from "../../api/api";

const WithdrawDetail = () => {
  const res = useLoaderData();
  const withdrawReq = res.data.withdrawReq;

  const dateCreated = new Date(withdrawReq.createdAt).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );

  const status = withdrawReq.active ? "Approved" : "Unapproved";

  return (
    <section className={classes.detail}>
      <div className={classes.content}>
        <span>Plan name</span>
        <span>{withdrawReq.subscription.plan.name}</span>
      </div>
      <div className={classes.content}>
        <span>Wallet</span>
        <span>{withdrawReq.wallet}</span>
      </div>
      <div className={classes.content}>
        <span>Amount</span>
        <span>{withdrawReq.amount.toFixed(2)}</span>
      </div>
      <div className={classes.content}>
        <span>Account Name</span>
        <span>{withdrawReq.user.name}</span>
      </div>
      <div className={classes.content}>
        <span>Date created</span>
        <span>{dateCreated}</span>
      </div>
      <div className={classes.content}>
        <span>Status</span>
        <span>{status}</span>
      </div>
    </section>
  );
};

export default WithdrawDetail;

export const loader = ({ params }) => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getOneWithdrawReq(params.id, jwt);
};
