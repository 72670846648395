import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";

import Deposit from "./Deposit";
import classes from "./Deposits.module.css";
import { getDepositReqs } from "../../api/api";

const Deposits = () => {
  const res = useLoaderData();
  const deposits = res.data.depositReqs;

  return (
    <section className={classes.deposits}>
      {deposits.map((data) => (
        <Deposit
          key={data._id}
          id={data._id}
          name={data.user.name}
          amount={data.amount}
          createdAt={data.createdAt}
        />
      ))}
    </section>
  );
};

export default Deposits;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  return getDepositReqs(jwt);
};
