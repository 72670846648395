import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import classes from "./Header.module.css";
import { signOut } from "../../api/api";
import { authActions } from "../../store/auth-slice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const setCookie = useCookies(["jwt"])[1];

  const accountsLink = isLoggedIn ? "/accounts" : "/login";
  const investmentsLink = isLoggedIn ? "/investments" : "/login";
  const withdrawalsLink = isLoggedIn ? "/withdrawals" : "/login";
  const depositsLink = isLoggedIn ? "/deposits" : "/login";

  const logoutHandler = async () => {
    dispatch(authActions.logout());
    const res = await signOut();
    if (res.status === "success") {
      setCookie("jwt", res.token);
    }
    navigate("/login", { replace: true });
  };

  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <Link to={accountsLink}>Accounts</Link>
        </li>
        <li>
          <Link to={investmentsLink}>Investments</Link>
        </li>
        <li>
          <Link to={withdrawalsLink}>Withdrawals</Link>
        </li>
        <li>
          <Link to={depositsLink}>Deposits</Link>
        </li>
        <li>
          {!isLoggedIn && <Link to="/login">Login</Link>}
          {isLoggedIn && (
            <Link to="/login" onClick={logoutHandler}>
              Logout
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Header;
