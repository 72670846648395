import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./Deposit.module.css";
import { approveDepositReq } from "../../api/api";
import { spinnerActions } from "../../store/spinner-slice";
import { alertActions } from "../../store/alert-slice";

const Deposit = (props) => {
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];
  const dateCreated = new Date(props.createdAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const approveHandler = async () => {
    dispatch(spinnerActions.showSpinner());

    const res = await approveDepositReq({ id: props.id }, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      window.location.reload();
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    dispatch(spinnerActions.hideSpinner());
  };

  return (
    <div className={classes.deposit}>
      <div className={classes.content}>
        <span>{props.name}</span>
      </div>
      <div className={classes.content}>
        <span>{props.amount}</span>
      </div>
      <div className={classes.content}>
        <span>{dateCreated}</span>
      </div>
      <div className={classes.content}>
        <button type="button" onClick={approveHandler}>
          Approve
        </button>
      </div>
    </div>
  );
};

export default Deposit;
