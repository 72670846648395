import useInput from "../../hooks/userInput";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

import classes from "./Login.module.css";
import { signIn } from "../../api/api";
import { spinnerActions } from "../../store/spinner-slice";
import { authActions } from "../../store/auth-slice";
import { alertActions } from "../../store/alert-slice";

const Login = () => {
  const dispatch = useDispatch();
  const setCookies = useCookies(["jwt"])[1];
  const navigate = useNavigate();
  const {
    value: emailInput,
    enteredValueIsValid: emailInputIsValid,
    hasError: emailInputIsInvalid,
    valueInputChangedHandler: emailInputChangedHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: passwordInput,
    enteredValueIsValid: passwordInputIsValid,
    hasError: passwordInputIsInvalid,
    valueInputChangedHandler: passwordInputChangedHandler,
    valueInputBlurHandler: passwordInputBlurHandler,
    reset: passwordInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (emailInputIsValid && passwordInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(spinnerActions.showSpinner());

    const data = {
      email: emailInput,
      password: passwordInput,
    };

    const res = await signIn(data);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({
          message: "Signed in successfully!",
          status: res.status,
        })
      );
      dispatch(authActions.login({ user: res.data.user }));
      setCookies("jwt", res.token);
      navigate("/investments", { replace: true });
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    emailInputReset();
    passwordInputReset();
    dispatch(spinnerActions.hideSpinner());
  };

  const emailInputClasses = emailInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const passwordInputClasses = passwordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <div className={emailInputClasses}>
        <label>Email</label>
        <input
          type="text"
          value={emailInput}
          onChange={emailInputChangedHandler}
          onBlur={emailInputBlurHandler}
        />
      </div>
      <div className={passwordInputClasses}>
        <label>Password</label>
        <input
          type="password"
          value={passwordInput}
          onChange={passwordInputChangedHandler}
          onBlur={passwordInputBlurHandler}
        />
      </div>
      <div className={classes.action}>
        <button type="submit" disabled={!formIsValid}>
          Login
        </button>
      </div>
    </form>
  );
};

export default Login;
