import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./Account.module.css";
import { deleteUser } from "../../api/api";
import { spinnerActions } from "../../store/spinner-slice";
import { alertActions } from "../../store/alert-slice";
import { Link } from "react-router-dom";

const Account = (props) => {
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];

  const deleteUserHandler = async () => {
    dispatch(spinnerActions.showSpinner());

    const res = await deleteUser(props.id, jwt);
    if (res === "") {
      dispatch(
        alertActions.setState({
          message: "Account deleted successfully.",
          status: "success",
        })
      );
      window.location.reload();
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    dispatch(spinnerActions.hideSpinner());
  };

  return (
    <div className={classes.account}>
      <div className={classes.content}>
        <span>{props.name}</span>
      </div>
      <div className={classes.content}>
        <span>{props.email}</span>
      </div>
      <div className={classes.content}>
        <span>{props.phone}</span>
      </div>
      <div className={classes.content}>
        <Link to={`/accounts/${props.id}`}>Details</Link>
      </div>
      <div className={classes.content}>
        <button type="button" onClick={deleteUserHandler}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default Account;
