import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import AdminLayout from "./components/pages/AdminLayout";
import ErrorModal from "./components/UI/ErrorModal";
import Login from "./components/Auth/Login";
import Accounts from "./components/accounts/Accounts";
import Investments from "./components/investments/Investments";
import Detail from "./components/investments/Detail";
import Withdraws from "./components/withdraws/Withdraws";
import WithdrawDetail from "./components/withdraws/WithdrawDetail";
import Deposits from "./components/deposits/Deposits";
import AccountDetail from "./components/accounts/AccountDetail";

import { loader as accountsLoader } from "./components/accounts/Accounts";
import { loader as investmentsLoader } from "./components/investments/Investments";
import { loader as investDetailLoader } from "./components/investments/Detail";
import { loader as withdrawsLoader } from "./components/withdraws/Withdraws";
import { loader as withdrawLoader } from "./components/withdraws/WithdrawDetail";
import { loader as depositsLoader } from "./components/deposits/Deposits";
import { loader as accountDetailLoader } from "./components/accounts/AccountDetail";

import LoadSpinner from "./components/UI/LoadSpinner";
import AlertModal from "./components/UI/AlertModal";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AdminLayout />} errorElement={<ErrorModal />}>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/accounts" element={<Accounts />} loader={accountsLoader} />
      <Route
        path="/accounts/:accountId"
        element={<AccountDetail />}
        loader={accountDetailLoader}
      />
      <Route
        path="/investments"
        element={<Investments />}
        loader={investmentsLoader}
      />
      <Route
        path="/investments/:id"
        element={<Detail />}
        loader={investDetailLoader}
      />
      <Route
        path="/withdrawals"
        element={<Withdraws />}
        loader={withdrawsLoader}
      />
      <Route
        path="/withdrawals/:id"
        element={<WithdrawDetail />}
        loader={withdrawLoader}
      />
      <Route path="/deposits" element={<Deposits />} loader={depositsLoader} />
    </Route>
  )
);

function App() {
  const showSpinner = useSelector((state) => state.spinner.showSpinner);
  const showModal = useSelector((state) => state.alert.showModal);

  return (
    <Fragment>
      {showSpinner && <LoadSpinner />}
      {showModal && <AlertModal />}
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
