import Cookies from "universal-cookie";
import { useLoaderData, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import classes from "./AccountDetail.module.css";
import { creditReferer } from "../../api/api";
import { getOneUser } from "../../api/api";
import { alertActions } from "../../store/alert-slice";
import { spinnerActions } from "../../store/spinner-slice";

const AccountDetail = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { jwt } = useCookies(["jwt"])[0];
  const res = useLoaderData();
  const user = res.data.user;

  const showCreditButton = user.referralEmail === "" ? false : true;

  const creditRefererHandler = async () => {
    dispatch(spinnerActions.showSpinner());
    const accountId = params.accountId;
    const res = await creditReferer(accountId, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      window.location.reload();
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }
    dispatch(spinnerActions.hideSpinner());
  };

  return (
    <section className={classes.detail}>
      <div className={classes.content}>
        <span>Name</span>
        <span>{user.name}</span>
      </div>
      <div className={classes.content}>
        <span>Email</span>
        <span>{user.email}</span>
      </div>
      <div className={classes.content}>
        <span>Phone</span>
        <span>{user.phone}</span>
      </div>
      <div className={classes.content}>
        <span>Referer's Email</span>
        <span>{user.referralEmail}</span>
      </div>
      {showCreditButton && (
        <div className={classes.action}>
          <button type="button" onClick={creditRefererHandler}>
            Credit Referer
          </button>
        </div>
      )}
    </section>
  );
};

export default AccountDetail;

export const loader = ({ params }) => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");
  const accountId = params.accountId;
  return getOneUser(accountId, jwt);
};
